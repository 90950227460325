document.addEventListener('DOMContentLoaded', function() {
    beginApp();
});

function beginApp() {
    Nav();
    loading();
}

let select = "";


function loading(){
    const beginning = document.querySelector('.home');
    beginning.classList.add('fade-in');
    const navi = document.querySelector('#home')
    navi.classList.add('selected')
}

function Nav() {
    const enlaces = document.querySelectorAll('.main-nav a');
    enlaces.forEach( enlace => {
        enlace.addEventListener('click', function(e) {
            if(e.target.innerHTML !== 'Resume'){
                removeSelection();
                e.preventDefault();
                e.target.classList.add('selected');
                select = e.target.innerHTML;
                select = select.toLowerCase();
                const card = document.querySelector('.' + select);
                card.classList.remove('hidden');
                card.classList.add('fade-in')
            }
        });
    });
}

function removeSelection() {
    const selected = document.querySelector('.fade-in');
    const chosen = document.querySelector('.selected');
    selected.classList.add('hidden');
    selected.classList.remove('fade-in');
    chosen.classList.remove('selected');
}